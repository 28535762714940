<template>
  <app-container :title="'实名认证'" :background="tool.getThemeList(theme)">
    <!-- 系统实名认证-->
    <div class="auth_box">
      <div class="auth_content">
        <div class="card_num">
          <span class="desc">卡号:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请填写卡号"
            v-model="card"
          />
        </div>
        <div class="card_num_underline"></div>
        <div class="real_name">
          <span class="desc">真实姓名:</span>
          <input
            class="real_name_input"
            type="text"
            style="border: none"
            placeholder="请输入真实姓名2一20个字符"
            v-model="realName"
            maxlength="20"
          />
        </div>
        <div class="card_name_underline"></div>
        <div class="id_card">
          <span class="desc">身份证号:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入身份证号码，19位"
            v-model="IDCard"
            maxlength="19"
          />
        </div>
        <div class="card_mobile_underline"></div>
        <!-- 手机验证码组件 -->
        <verification-code ref="getMobile"></verification-code>
        <div class="card_code_underline"></div>
        <div class="code_num">
          <span class="desc">验证码:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入验证码"
            v-model="VerifyCode"
            maxlength="6"
          />
        </div>
        <div class="card_code_num_underline"></div>
      </div>
    </div>
    <div class="submit_button" @click="submit">提交认证</div>
    <div class="explain">
      <div class="explain_desc">温馨提示:</div>
      <div class="explain_text">
        1、当前的手机号码的开户信息必须跟当前填写的身份证信息一致
      </div>
      <div class="explain_text">2、如果信息不一致将会提示认证失败</div>

      <div class="explain_text">3、如果认证失败，请尝试手动上传</div>
    </div>
  </app-container>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
      card: "", //卡号
      realName: "", //真实姓名
      IDCard: "", //身份证
      VerifyCode: "", //验证码
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的ctx
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $store: store } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //提交认证
      submit() {
        ctx.$refs.getMobile.realName = state.realName; //传真实姓名
        ctx.$refs.getMobile.IDCard = state.IDCard; //传身份证号码
        ctx.$refs.getMobile.VerifyCode = state.VerifyCode; //传验证码
        ctx.$refs.getMobile.AuthenTicationAuto_(); //调用三要素系统认证接口
      },
      //检测路由参数
      judgeRoute() {
        if (route.query && route.query.card) {
          state.card = route.query.card;
        }
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      if (Object.keys(info).length > 0) {
        state.card = info.virtualId || info.iccid;
      } else {
        methods.judgeRoute();
      }
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.auth_box {
  width: 690px;
  height: 460px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 20px;
  .auth_content {
    width: 630px;
    height: 800px;
    margin: 0 auto;
    .card_num,
    .code_num,
    .real_name,
    .id_card {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .real_name_input {
        width: 430px;
      }
    }

    .card_num,
    .code_num {
      width: 600px;
      justify-content: space-between;
      .desc {
        padding-left: 4px;
      }
      input {
        width: 430px;
        margin-right: 60px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .code_num {
      input {
        width: 400px;
        margin-right: 30px;
      }
    }

    .real_name,
    .id_card {
      width: 630px;
      margin-left: 16px;
      input {
        width: 430px;
        margin-left: 40px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .card_num_underline,
    .card_name_underline,
    .card_mobile_underline,
    .card_code_underline,
    .card_code_num_underline {
      height: 1px;
      background: #f2f2f2;
    }
    .card_num_underline {
      width: 490px;
      margin: 0 120px;
    }
    .card_name_underline {
      width: 430px;
      margin: 0 180px;
    }
    .card_mobile_underline {
      width: 430px;
      margin: 0 184px;
    }
    .card_code_underline {
      width: 426px;
      margin: 0 190px;
    }
    .card_code_num_underline {
      width: 440px;
      margin: 0 186px;
    }
  }
}

.explain {
  width: 690px;
  height: 188px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .explain_desc {
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
  }
  .explain_text {
    width: 690px;
    font: {
      size: 22px;
      weight: 400;
    }
    color: #777777;
  }
}
.submit_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
  line-height: 84px;
}
</style>
